import React, { useState, useReducer } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faCheck,
  faBullseye,
  faVectorSquare,
  faHands,
  faGraduationCap,
  faCrown,
  faHandHoldingHeart,
  faAward,
  faAngleLeft,
  faAngleDown,
  faShekelSign
} from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faClock } from '@fortawesome/free-regular-svg-icons'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackToTopButton from '../components/BackToTopButton'
import { encode, fetchWitRetry } from '../utils'
import { fireEvent } from '../analytics'
import i18n from '../locales/he.yaml'

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (!action.value) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

const FEATURE_ICON = [
  faCheck,
  faHands,
  faCalendarCheck,
  faVectorSquare,
  faBullseye,
  faGraduationCap
]

const WHY_US_ICON = [faCrown, faHandHoldingHeart, faAward]

const WorkshopsQuery = graphql`
  query WorkshopsQuery {
    genie: file(
      relativePath: { eq: "קארין-עמנואל-עם-עוגת-גי׳ני-של-אלאדין-בתלת-מימד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    fashion: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-ליום-הולדת-של-תופרת-סטייליסטית-ומעצבת-אופנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    alice: file(
      relativePath: {
        eq: "עוגה-שיגיונית-מעוצבת-ליום-הולדת-אליס-בארץ-הפלאות-עם-הזחל-המעשן-קלפים-נרגילה-והחתול-צ׳שייר-החייכן-מפוסלים-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ratatouille: file(
      relativePath: {
        eq: "עוגת-רטטוי-מעוצבת-בבצק-סוכר-עם-רמי-העכבר-השף-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    shoe: file(
      relativePath: {
        eq: "עוגת-נייקי-בצורת-נעל-ג׳ורדן-בתלת-מימד-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    giraffe: file(
      relativePath: {
        eq: "ג׳ירפה-מפוסלת-בבצק-סוכר-מחזיקה-עוגת-2-קומות-מרחפת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    vintageCake: file(
      relativePath: {
        eq: "עוגת-חתונה-כפרית-ב-4-קומות-מרחפת-עם-זוג-ינשופים-מפוסלים-בבצק-סוכר-ופרחים-ופרפרים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    saharaviram: file(relativePath: { eq: "סהר-אבירם.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
    henatias: file(relativePath: { eq: "חן-אטיאס.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
    ruthturtel: file(relativePath: { eq: "רות-טורטל.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
  }
`

const WorkshopsTemplate = ({ pathname, images }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [registrationData, dispatchRegistrationData] = useReducer(reducer, {})
  const {
    fashion,
    alice,
    ratatouille,
    survival,
    shoe,
    giraffe,
    vintageCake,
    saharaviram,
    henatias,
    ruthturtel
  } = images
  const PROBLEM_IMAGE = [fashion, alice, ratatouille]
  const WORKSHOP_3D_IMAGE = [giraffe, shoe, vintageCake]
  const WORKSHOPS_IMAGES = [WORKSHOP_3D_IMAGE]
  return (
    <>
      <BackToTopButton selectorId='problems' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-12-mobile is-10-tablet is-8-desktop'>
                <h2 className='is-size-5 has-text-nude'>
                  {i18n.workshops.subtitle}
                </h2>
                <h1 className='is-size-4 is-margin-bottom-6'>
                  {i18n.workshops.title}
                </h1>
                <div className='has-text-centered'>
                  <a
                    href={`${pathname}#workshops-workshops`}
                    className='button is-nude is-medium'
                  >
                    {i18n.workshops.callToAction}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              {i18n.workshops.benefits.map((benefit, index) => (
                <div key={index} className='column has-text-centered'>
                  <FontAwesomeIcon icon={faHeart} className='has-text-nude' />
                  <div className='content'>
                    <h3 className='is-margin-top-4'>{benefit.title}</h3>
                    <p>{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='is-flex justify-center is-margin-bottom-4'>
              <figure className='image is-48x48'>
                <GatsbyImage
                  image={getImage(saharaviram)}
                  className='is-rounded'
                  title={i18n.workshops.benefitsQuote.name}
                  alt=''
                />
              </figure>
            </div>
            <div className='is-flex justify-center'>
              <p className='has-text-centered is-size-5 has-text-gold-black'>
                {i18n.workshops.benefitsQuote.text}
              </p>
            </div>
            <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
              {i18n.workshops.benefitsQuote.name}
            </p>
          </div>
        </div>
      </div>
      {i18n.workshops.problems.bullets.map((bullet, index) => (
        <div
          id={index === 0 ? 'problems' : null}
          key={index}
          className={clsx('hero', {
            'has-background-nude-lighter': index % 2 === 0
          })}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 0
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(PROBLEM_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(PROBLEM_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <a
              href={`${pathname}#workshops-workshops`}
              className='button is-nude is-medium'
            >
              {i18n.workshops.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-7'>
              <h3>{i18n.workshops.features.title}</h3>
            </div>
            <div className='columns is-multiline is-mobile'>
              {i18n.workshops.features.bullets.map((bullet, index) => (
                <div
                  key={index}
                  className='column is-one-third-desktop is-half-tablet is-full-mobile'
                >
                  <div
                    className='box has-text-centered lift'
                    style={{ height: '100%' }}
                  >
                    <FontAwesomeIcon
                      icon={FEATURE_ICON[index]}
                      className='has-text-gold'
                    />
                    <h4 className='is-margin-top-4'>{bullet.title}</h4>
                    <p>{bullet.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-7'>
              <h3>{i18n.workshops.whyUs.title}</h3>
            </div>
            <div className='columns'>
              {i18n.workshops.whyUs.bullets.map((bullets, index) => (
                <div key={index} className='column has-text-centered'>
                  <FontAwesomeIcon
                    icon={WHY_US_ICON[index]}
                    className='has-text-nude'
                  />
                  <h4 className='is-margin-top-4'>{bullets.title}</h4>
                  <p>{bullets.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <a
              href={`${pathname}#workshops-workshops`}
              className='button is-nude is-medium'
            >
              {i18n.workshops.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='is-flex justify-center is-margin-bottom-4'>
              <figure className='image is-48x48'>
                <GatsbyImage
                  image={getImage(henatias)}
                  className='is-rounded'
                  title={i18n.workshops.whyUsQuote.name}
                  alt=''
                />
              </figure>
            </div>
            <div className='is-flex justify-center'>
              <p className='has-text-centered is-size-5 has-text-gold-black'>
                {i18n.workshops.whyUsQuote.text}
              </p>
            </div>
            <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
              {i18n.workshops.whyUsQuote.name}
            </p>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered is-vcentered'>
              <div className='column content is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.workshops.about.title}</h3>
                <p>
                  {i18n.workshops.about.description.map((t) =>
                    t.text ? (
                      <React.Fragment key={t.text}>{t.text}</React.Fragment>
                    ) : (
                      <span key={t.number} className='is-family-secondary'>
                        &nbsp;{t.number}&nbsp;
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={i18n.workshops.about.image.title}
                  alt={i18n.workshops.about.image.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.workshops.about.image.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-7'>
              <h3>{i18n.workshops.plan.title}</h3>
            </div>
            <div className='columns'>
              {i18n.workshops.plan.steps.map((step, index) => (
                <React.Fragment key={index}>
                  <div className='column is-one-quarter'>
                    <div className='has-text-centered'>
                      <h4>{step.title}</h4>
                      <div className='is-flex justify-center'>
                        <p className='has-text-centered has-text-gold-black'>
                          {step.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  {index < 2 && (
                    <div className='column is-flex items-center justify-center'>
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        size='2x'
                        className='has-text-nude is-hidden-mobile'
                      />
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size='2x'
                        className='has-text-nude is-hidden-tablet'
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <a
              href={`${pathname}#workshops-workshops`}
              className='button is-nude is-medium'
            >
              {i18n.workshops.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='is-flex justify-center is-margin-bottom-4'>
              <figure className='image is-48x48'>
                <GatsbyImage
                  image={getImage(ruthturtel)}
                  className='is-rounded'
                  title={i18n.workshops.planQuote.name}
                  alt=''
                />
              </figure>
            </div>
            <div className='is-flex justify-center'>
              <p className='has-text-centered is-size-5 has-text-gold-black'>
                {i18n.workshops.planQuote.text}
              </p>
            </div>
            <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
              {i18n.workshops.planQuote.name}
            </p>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container has-text-centered content'>
            <h3 className='is-margin-bottom-6'>
              {i18n.workshops.investment.title}
            </h3>
            <div>
              {i18n.workshops.investment.description.map(
                (description, index) => (
                  <p key={index} className='has-text-grey'>
                    {description.map((o, index) =>
                      o.regular ? (
                        <span key={index}>{o.regular} </span>
                      ) : (
                        <strong key={index}>{o.bold} </strong>
                      )
                    )}
                  </p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div id='workshops-workshops' className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content'>
            <div className='has-text-centered'>
              <h4 className='is-margin-bottom-0'>
                {i18n.workshops.workshops.title}
              </h4>
            </div>
          </div>
        </div>
      </div>
      {[i18n.workshops.workshops['3d']].map((workshopType, workshopIndex) => {
        const workshopTypeTitle = workshopType.title
          .map((t) => t.text || t.number)
          .join(' ')
        return (
          <div
            key={workshopIndex}
            id={workshopTypeTitle}
            className={clsx('hero', {
              'has-background-nude-lighter': workshopIndex === 1
            })}
          >
            <div className='hero-body'>
              <div className='container'>
                <div className='has-text-centered is-margin-bottom-7 content'>
                  <h3>
                    {workshopType.title.map((t, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <span>&nbsp;</span>}
                        <span
                          className={clsx({
                            'is-family-secondary': t.number
                          })}
                        >
                          {t.text || t.number}
                        </span>
                      </React.Fragment>
                    ))}
                  </h3>
                  <div className='is-flex justify-center is-margin-bottom-4'>
                    <p className='has-text-centered has-text-gold-black'>
                      {workshopType.subtitle.map((t) =>
                        t.text ? (
                          <React.Fragment key={t.text}>{t.text}</React.Fragment>
                        ) : (
                          <span key={t.number} className='is-family-secondary'>
                            &nbsp;{t.number}&nbsp;
                          </span>
                        )
                      )}
                    </p>
                  </div>
                  <small>
                    <em>{i18n.workshops.workshops.imagesForIllustration}</em>
                  </small>
                </div>
                <div className='columns is-multiline'>
                  {workshopType.workshops.map((workshop, index) => {
                    const workshopTitle = workshop.title
                      .map((t) => t.text || t.number)
                      .join(' ')
                    return (
                      <div
                        key={index}
                        className='column is-4-desktop is-7-tablet is-10-mobile is-full-real-mobile'
                        style={{ margin: '0 auto' }}
                      >
                        <div
                          className='card'
                          style={{
                            height: '100%',
                            paddingBottom: '5rem'
                          }}
                        >
                          <header className='card-header direction-column'>
                            <div className='card-header-title is-centered'>
                              <FontAwesomeIcon icon={faClock} />
                              &nbsp;
                              <span className='is-family-secondary has-text-weight-normal'>
                                {workshop.length}
                              </span>
                            </div>
                            <h4 className='card-header-title is-centered is-size-5 is-margin-bottom-4'>
                              {workshop.title.map((t, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && <span>&nbsp;</span>}
                                  <span
                                    className={clsx('has-text-centered', {
                                      'is-family-secondary': t.number
                                    })}
                                  >
                                    {t.text || t.number}
                                  </span>
                                </React.Fragment>
                              ))}
                            </h4>
                          </header>
                          <div className='card-image'>
                            <GatsbyImage
                              image={getImage(
                                WORKSHOPS_IMAGES[workshopIndex][index]
                              )}
                              title={workshop.image.title}
                              alt={workshop.image.alt}
                              className='live-image'
                              style={{
                                maxWidth: getImage(PROBLEM_IMAGE[index]).width,
                                margin: '0 auto' // used to center the image
                              }}
                            />
                          </div>
                          <div className='card-content'>
                            <div className='content'>
                              <div className='has-text-centered is-family-secondary'>
                                <span className='is-size-5 has-text-weight-bold'>
                                  {workshop.price}
                                </span>
                                <FontAwesomeIcon
                                  icon={faShekelSign}
                                  className='is-margin-left-2'
                                  size='xs'
                                />
                              </div>
                              <div className='accordion'>
                                <input
                                  type='checkbox'
                                  id={`accordion_${encodeURIComponent(
                                    workshopTitle
                                  )}`}
                                  hidden
                                />
                                <label
                                  className='accordion-header has-text-centered'
                                  htmlFor={`accordion_${encodeURIComponent(
                                    workshopTitle
                                  )}`}
                                >
                                  <a className='button is-text has-text-grey'>
                                    {i18n.workshops.workshops.moreDetails}
                                  </a>
                                </label>
                                <div className='accordion-body'>
                                  <ul>
                                    {workshop.bullets.map((bullet) => (
                                      <li key={bullet}>{bullet}</li>
                                    ))}
                                  </ul>
                                  {workshop.hint && (
                                    <small>
                                      <em>{workshop.hint}</em>
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className='has-text-centered'
                            style={{
                              width: '100%',
                              position: 'absolute',
                              bottom: '1.5rem'
                            }}
                          >
                            <a
                              href={`${pathname}#registration`}
                              className='button is-nude is-outlined'
                              onClick={() =>
                                dispatchRegistrationData({
                                  key: 'workshop',
                                  value: workshop.title
                                    .map((t) => t.text || t.number)
                                    .join(' ')
                                })
                              }
                            >
                              {i18n.workshops.chooseWorkshop}
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container is-flex justify-center'>
            <p className='has-text-centered is-size-5 has-text-gold-black'>
              {i18n.workshops.aspirationalIdentityMessage}
            </p>
          </div>
        </div>
      </div>
      <div id='registration' className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <div className='card'>
                  <header className='card-header direction-column items-center'>
                    <h3 className='card-header-title is-centered is-size-4'>
                      {i18n.workshops.registration.title}
                    </h3>
                    <p className='is-padding-left-6 is-padding-right-6 is-padding-bottom-4'>
                      {i18n.workshops.registration.subtitle}
                    </p>
                  </header>
                  <div className='card-content'>
                    <form
                      name='workshops'
                      method='POST'
                      action='/workshops/thanks/'
                      data-netlify='true'
                      data-netlify-honeypot='bot-field'
                      onSubmit={async (e) => {
                        e.preventDefault()
                        setIsLoading(true)
                        await fireEvent('Lead')
                        const form = e.target
                        try {
                          await fetchWitRetry('/', {
                            method: 'POST',
                            headers: {
                              'Content-Type':
                                'application/x-www-form-urlencoded'
                            },
                            body: encode({
                              'form-name': form.getAttribute('name'),
                              ...registrationData
                            })
                          })
                          setIsLoading(false)
                          dispatchRegistrationData({
                            type: 'INIT'
                          })
                          // for some reason without setTimeout the first time it triggers without opening the modal
                          await new Promise((resolve) => {
                            setTimeout(resolve, 0)
                          })
                          navigate(form.getAttribute('action'), {
                            state: { modal: true }
                          })
                        } catch {
                          void 0
                        }
                      }}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type='hidden' name='form-name' value='workshops' />
                      <div className='field' hidden>
                        <input
                          name='bot-field'
                          onChange={(e) =>
                            dispatchRegistrationData({
                              key: 'bot-field',
                              value: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-workshop'>
                          {i18n.workshops.registration.workshop.title}
                        </label>
                        <div className='control'>
                          <div className='select is-fullwidth'>
                            <select
                              id='input-workshop'
                              name='workshop'
                              value={registrationData.workshop || ''}
                              onChange={(e) => {
                                dispatchRegistrationData({
                                  key: 'workshop',
                                  value: e.target.value
                                })
                                e.target.setCustomValidity('')
                              }}
                              required
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  i18n.workshops.registration.errors.workshop
                                )
                              }
                            >
                              <option value='' disabled />
                              {[i18n.workshops.workshops['3d']].map(
                                (workshopType) => {
                                  const workshopTypeTitle = workshopType.title
                                    .map((t) => t.text || t.number)
                                    .join(' ')
                                  return (
                                    <React.Fragment key={workshopTypeTitle}>
                                      <optgroup label={workshopTypeTitle}>
                                        {workshopType.workshops.map(
                                          (workshop) => {
                                            const workshopTitle = workshop.title
                                              .map((t) => t.text || t.number)
                                              .join(' ')
                                            return (
                                              <option
                                                key={workshopTitle}
                                                value={workshopTitle}
                                              >
                                                {workshopTitle}
                                              </option>
                                            )
                                          }
                                        )}
                                      </optgroup>
                                    </React.Fragment>
                                  )
                                }
                              )}
                              <option value='' disabled />
                              <option
                                value={
                                  i18n.workshops.registration.workshop.other
                                }
                              >
                                {i18n.workshops.registration.workshop.other}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-name'>
                          {i18n.workshops.registration.name.title}
                        </label>
                        <div className='control'>
                          <input
                            id='input-name'
                            className='input'
                            type='text'
                            name='name'
                            value={registrationData.name || ''}
                            placeholder={
                              i18n.workshops.registration.name.placeholder
                            }
                            onChange={(e) => {
                              dispatchRegistrationData({
                                key: 'name',
                                value: e.target.value
                              })
                              e.target.setCustomValidity('')
                            }}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                i18n.workshops.registration.errors.name
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-phone'>
                          {i18n.workshops.registration.phone.title}
                        </label>
                        <div className='control'>
                          <input
                            id='input-phone'
                            className='input is-family-secondary'
                            type='tel'
                            pattern='[0-9]{9,10}'
                            name='phone'
                            value={registrationData.phone || ''}
                            placeholder={
                              i18n.workshops.registration.phone.placeholder
                            }
                            onChange={(e) => {
                              dispatchRegistrationData({
                                key: 'phone',
                                value: e.target.value
                              })
                              if (
                                new RegExp(
                                  e.target.getAttribute('pattern')
                                ).test(e.target.value)
                              ) {
                                e.target.setCustomValidity('')
                              }
                            }}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                i18n.workshops.registration.errors.phone
                              )
                            }
                          />
                        </div>
                        <p className='help'>
                          {i18n.workshops.registration.phone.hint}
                        </p>
                      </div>
                      <button
                        className={clsx(
                          'button is-fullwidth is-nude is-medium',
                          { 'is-loading': isLoading }
                        )}
                        type='submit'
                        disabled={isLoading}
                      >
                        {i18n.workshops.registration.submit}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Workshops = ({ location: { pathname } }) => {
  const data = useStaticQuery(WorkshopsQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.workshops.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.genie),
          alt: i18n.workshops.mainImage.alt
        }}
      />
      <WorkshopsTemplate pathname={pathname} images={data} />
    </Layout>
  )
}

export default Workshops
